<template>
  <div>
    <p class="container" style="text-align: center">
      Bienvenido al portal de la CVC donde podrás registrar tu Petición, Queja,
      Reclamo o Denuncias
    </p>
    <p class="container" style="font-weight: bold; text-align: center">
      DENUNCIOS AMBIENTALES
    </p>
    <div class="container">
      <!-- DENUNCIOS AMBIENTALES -->
      <div class="accordion" role="tablist">
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO AGUA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO AGUA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado del recurso agua.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciaagua()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO AIRE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO AIRE</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado del recurso aire.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciaaire()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO BOSQUE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-3
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO BOSQUE</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado de recurso bosque.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciabosque()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO FAUNA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-4
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO FAUNA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado del recurso fauna.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciafauna()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO FLORA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-5
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO FLORA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado del recurso flora.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciaflora()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO PAISAJE -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-6
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO PAISAJE</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado del recurso paisaje.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciapaisaje()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES CONTRA EL RECURSO SUELO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-7
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES CONTRA EL RECURSO SUELO</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona en la que
                        indica la vulneración a una norma ambiental o el manejo
                        inadecuado del recurso suelo.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciasuelo()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DENUNCIA POR INFRACCIONES POR RUIDO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-8
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR INFRACCIONES POR RUIDO</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la infracción que da a conocer una persona, en la que
                        indica la vulneración a una norma ambiental por RUIDO.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDenunciaruido()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <br />
      <p class="container" style="font-weight: bold; text-align: center">
        DENUNCIAS POR CORRUPCIÓN
      </p>
      <!-- DENUNCIAS POR CORRUPCIÓN -->
      <div class="accordion" role="tablist">
        <!-- DENUNCIA POR CORRUPCIÓN-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-38
              variant="info"
              style="background: #45b39d"
              >DENUNCIA POR CORRUPCIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-38"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        En este Formulario Usted podrá informar a la Corporación
                        Autónoma Regional del Valle del Cauca sobre situaciones
                        que presuntamente puedan tratarse de hechos de
                        corrupción cometidos por nuestros servidores públicos.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldenunciacorrupcion()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <br />
      <p class="container" style="font-weight: bold; text-align: center">
        DERECHOS DE PETICIÓN
      </p>
      <!-- DERECHOS DE PETICIÓN -->
      <div class="accordion" role="tablist">
        <!-- DERECHO DE PETICION, DE INTERÉS PARTICULAR -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-13
              variant="info"
              style="background: #3498db"
              >DERECHO DE PETICIÓN, DE INTERÉS PARTICULAR</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-13"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Derecho fundamental que tiene toda persona a presentar
                        solicitudes respetuosas a las autoridades, por motivos
                        de interés particular.
                      </b-card-text>
                      <center>
                        <b-button
                          @click="urldatosDerechopeticioninteresparticular()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea
                        </b-button>
                      </center>
                    </b-card-body>
                    <b-card-body>
                      <h5 style="font-size: 18px; text-align: center">
                        Información del trámite
                      </h5>
                      <b-card-text>
                        Actualización de datos de terceros<br />
                        <p style="font-size: 13px">
                          Consulte y/o descargue formatos para actualización de
                          datos de terceros. Después de diligenciado, radíquelo
                          en el ícono verde como PDF o imagen JPG, junto con el
                          documento soporte (leer "Anexo" en el formato).<br />
                          <b>NOTA :</b> Para la actualización de la dirección de
                          correspondencia asociada a una cuenta de un servicio
                          prestado por la Corporación, se debe acercar a la
                          Dirección Ambiental Regional (DAR) que le corresponda.
                        </p>
                        <div>
                          <b-form-select
                            v-model="selected"
                            :options="options"
                            @change="abrirDoc()"
                          ></b-form-select>
                        </div>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DERECHO DE PETICION,  DE INTERÉS GENERAL-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-11
              variant="info"
              style="background: #3498db"
              >DERECHO DE PETICIÓN, DE INTERÉS GENERAL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-11"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Derecho fundamental que tiene toda persona a presentar
                        solicitudes respetuosas a las autoridades, por motivos
                        de interés general.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDerechopeticioninteresgeneral()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DERECHO DE PETICION, DE CONSULTA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-9
              variant="info"
              style="background: #3498db"
              >DERECHO DE PETICIÓN, DE CONSULTA</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Derecho fundamental para acudir ante las autoridades
                        públicas para que manifiesten su parecer sobre materias
                        relacionadas con sus funciones.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDerechopeticionconsulta()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DERECHO DE PETICION, DE INFORMACIÓN (COPIAS) -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-12
              variant="info"
              style="background: #3498db"
              >DERECHO DE PETICIÓN, DE INFORMACIÓN(COPIAS)</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-12"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Facultad de las personas para solicitar y obtener acceso
                        a la información sobre las actuaciones derivadas del
                        cumplimiento de las funciones.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDerechopeticioncopias()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- CERTIFICACIONES -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-10
              variant="info"
              style="background: #3498db"
              >CERTIFICACIONES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-10"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Solicitudes relacionadas con materias desarrolladas en
                        la Corporación, tales como: Certificaciones laborales o
                        de prestacion de servicios.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosDerechopeticioncertificaciones()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ENTES DE CONTROL-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-14
              variant="info"
              style="background: #3498db"
              >ENTES DE CONTROL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-14"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Requerimientos o solicitudes de organismos con funciones
                        relacionadas con el control disciplinario, defender al
                        pueblo y el control fiscal.
                        <center>
                          <br />
                          <b-button
                            @click="urldatosentesdecontrol()"
                            variant="outline-success"
                          >
                            <b-icon
                              icon="receipt-cutoff"
                              aria-hidden="true"
                            ></b-icon>
                            Diligencie aquí formulario en línea</b-button
                          >
                        </center>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- INFORMES A CONGRESISTAS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-15
              variant="info"
              style="background: #3498db"
              >INFORMES A CONGRESISTAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-15"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Informes solicitados a la Corporación de acuerdo a sus
                        competencias y funciones por parte de miembros del
                        Congreso de la Republica.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosinformescongresistas()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PETICIONES ENTRE AUTORIDADES -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-16
              variant="info"
              style="background: #3498db"
              >PETICIONES ENTRE AUTORIDADES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-16"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Peticiones elevadas a la Corporación en razón a sus
                        competencias por parte de autoridades administrativas
                        del orden nacional.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatospeticionesentreautoridades()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <br />
      <p class="container" style="font-weight: bold; text-align: center">
        ENTREGA DE DOCUMENTOS
      </p>
      <!-- ENTREGA DE DOCUMENTOS -->
      <div class="accordion" role="tablist">
        <!-- CITACIONES DE AUTORIDADES JUDICIALES -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-17
              variant="info"
              style="background: #45b39d"
              >CITACIONES DE AUTORIDADES JUDICIALES</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-17"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Citaciones y notificaciones judiciales, correspondiente
                        a los tribunales y juzgados.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatoscitacionautojudi()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DOCUMENTOS DE SEGUIMIENTO A DERECHOS AMBIENTALES OTORGADOS -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-18
              variant="info"
              style="background: #45b39d"
              >DOCUMENTOS DE SEGUIMIENTO A DERECHOS AMBIENTALES
              OTORGADOS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-18"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Documentos por medio de los cuales se realiza
                        seguimiento a derechos ambientales otorgados y
                        cumpliento de obligaciones.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosdocuseguidereambioto()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- DOCUMENTOS PARA CONTRATACION -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-19
              variant="info"
              style="background: #45b39d"
              >DOCUMENTOS PARA CONTRATACIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-19"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Documentos correspondientes a los procesos de
                        contratación de bienes y srvicios adelantados por la
                        CVC.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosdocucontratacion()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ENTREGA DE DOCUMENTOS-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-20
              variant="info"
              style="background: #45b39d"
              >ENTREGA DE DOCUMENTOS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-20"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Entrega de documentación adicional o complementaria a
                        trámites y peticiones radicadas en la CVC.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosentregadocu()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PRESENTACIÓN DE COSTOS DE OPERACION PARA SEGUIMIENTO -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-37
              variant="info"
              style="background: #45b39d"
              >PRESENTACIÓN DE COSTOS DE OPERACIÓN PARA SEGUIMIENTO</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-37"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Presentacion de costos requeridos para la
                        administracion, operacion y mantenimiento hasta la
                        desmantelacion del proyecto, obra o actividad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          href="https://registrosambientales.cvc.gov.co/"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- INFORMACION FALTANTE DERECHO AMBIENTAL -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-21
              variant="info"
              style="background: #45b39d"
              >INFORMACIÓN FALTANTE DERECHO AMBIENTAL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-21"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Información o documentacion requerida para la
                        continuacion de los tramites ambientales adelantados en
                        la CVC.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosinfofaltantedereambi()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- INFORMACIÓN ADICIONAL DERECHO AMBIENTAL -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-22
              variant="info"
              style="background: #45b39d"
              >INFORMACIÓN ADICIONAL DERECHO AMBIENTAL</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-22"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Información o documentacion adicional para la
                        continuacion de los tramites ambientales adelantados en
                        la CVC.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosinfoadiconaldereambi()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PLAN DE FERTILIZACION PARA EL SECTOR PORCICOLA -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-23
              variant="info"
              style="background: #45b39d"
              >PLAN DE FERTILIZACIÓN PARA EL SECTOR PORCÍCOLA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-23"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Plan de manejo de los nutrientes de la excreta para la
                        producción de cosechas.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosporcicola()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- PRESENTACIÓN DE COSTOS DE OPERACION PARA SEGUIMIENTO 
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-24
              variant="info"
              style="background: #45b39d"
              >PRESENTACIÓN DE COSTOS DE OPERACIÓN PARA SEGUIMIENTO</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-24"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Presentacion de costos requeridos para la
                        administracion, operacion y mantenimiento hasta la
                        desmantelacion del proyecto, obra o actividad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatoscostosopeseguimiento()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>-->
      </div>
      <br />
      <p class="container" style="font-weight: bold; text-align: center">
        INTERPOSICIÓN DE RECURSO ADMINISTRATIVO
      </p>
      <!-- INTERPOSICIÓN DE RECURSO ADMINISTRATIVO -->
      <div class="accordion" role="tablist">
        <!-- RECURSO DE APELACIÓN-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-25
              variant="info"
              style="background: #3498db"
              >RECURSO DE APELACIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-25"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Recurso administrativo que se interpone directamente
                        para que se surta ante el inmediato superior del
                        funcionario que tomó la decisión.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosrecursoapelacion()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- RECURSO DE QUEJA-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-26
              variant="info"
              style="background: #3498db"
              >RECURSO DE QUEJA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-26"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Recurso administrativo que puede interponerse cuando se
                        rechace el de apelación ante el superior del funcionario
                        que dictó la decisión.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosrecursoqueja()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- RECURSO DE REPOSICIÓN-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-27
              variant="info"
              style="background: #3498db"
              >RECURSO DE REPOSICIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-27"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Recurso administrativo que se interpone ante el
                        funcionario que tomó la decisión, con el fin de que la
                        aclare, revoque o adicione.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosrecursoreposicion()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- RECURSO DE REPOSICIÓN Y EN SUBSIDIO DE APELACIÓN-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-28
              variant="info"
              style="background: #3498db"
              >RECURSO DE REPOSICIÓN Y EN SUBSIDIO DE APELACIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-28"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Recurso se interpone en subsidio del recurso de
                        apelacion para que se surta ante el inmediato superior
                        de quien tomó la decisión.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosrecursoreposicionapelacion()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <br />
      <p class="container" style="font-weight: bold; text-align: center">
        RECLAMOS
      </p>
      <!-- RECLAMOS -->
      <div class="accordion" role="tablist">
        <!-- RECLAMO O ACLARACION POR COBRO DE TASA DE USO DE AGUA-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-29
              variant="info"
              style="background: #45b39d"
              >RECLAMO O ACLARACIÓN POR COBRO DE TASA DE USO DE AGUA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-29"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es el derecho que tiene toda persona de exigir,
                        reivindicar o demandar la aclaracion por el cobro tasa
                        de uso de agua.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosreclamocobrotua()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- RECLAMO O ACLARACION POR COBRO DE TASA RETRIBUTIVA-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-30
              variant="info"
              style="background: #45b39d"
              >RECLAMO O ACLARACIÓN POR COBRO DE TASA RETRIBUTIVA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-30"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es el derecho que tiene toda persona de exigir,
                        reivindicar o demandar la aclaracion por el cobro de
                        tasa retributiva.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosreclamocobrotr()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- RECLAMO POR PRESTACION INDEBIDA DE UN SERVICIO O FALTA DE ATENCIÓN DE LA CVC-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-31
              variant="info"
              style="background: #45b39d"
              >RECLAMO POR PRESTACIÓN INDEBIDA DE UN SERVICIO O FALTA DE
              ATENCIÓN DE LA CVC</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-31"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es el derecho que tiene toda persona de exigir,
                        reivindicar o demandar una solución, ya sea por motivo
                        general o particular.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosreclamopresentacionindebidacvc()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <br />
      <p class="container" style="font-weight: bold; text-align: center">
        OTROS TRÁMITES
      </p>
      <!-- OTROS TRÁMITES -->
      <div class="accordion" role="tablist">
        <!-- AUDIENCIA PUBLICA-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-32
              variant="info"
              style="background: #3498db"
              >AUDIENCIA PUBLICA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-32"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Intervención en los procesos administrativos
                        ambientales; permite a la ciudadanía participar en la
                        toma de decisiones en materia ambiental.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosAudienciap()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- ELOGIOS-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-33
              variant="info"
              style="background: #3498db"
              >ELOGIOS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-33"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la manifestación verbal, escrita o electrónica que se
                        presenta ante la entidad, con el fin de reconocer los
                        méritos de la Corporación.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatoselogios()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- QUEJA-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-34
              variant="info"
              style="background: #3498db"
              >QUEJA</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-34"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (14)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la manifestacion de protesta, censura, descontento,
                        que formula una persona en relacion con una conducta que
                        considera irregular.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatosqueja()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- SUGERENCIAS-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-35
              variant="info"
              style="background: #3498db"
              >SUGERENCIAS</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-35"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (15)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la manifestación de una idea o propuesta para mejorar
                        el servicio o la gestión de la entidad.
                      </b-card-text>
                      <center>
                        <br />
                        <b-button
                          @click="urldatossugerencias()"
                          variant="outline-success"
                        >
                          <b-icon
                            icon="receipt-cutoff"
                            aria-hidden="true"
                          ></b-icon>
                          Diligencie aquí formulario en línea</b-button
                        >
                      </center>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- INVITACIÓN-->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-36
              variant="info"
              style="background: #3498db"
              >INVITACIÓN</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-36"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card
                no-body
                class="overflow-hidden"
                style="background: #f5f5f5"
              >
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      src="../../../img/Otros (16)_10x15.jpg"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="">
                      <b-card-text style="text-align: justify">
                        Es la invitación que efectúa una persona natural o
                        jurídica a la CVC a participar en un evento.
                        <center>
                          <br />
                          <b-button
                            @click="urldatosinvitacion()"
                            variant="outline-success"
                          >
                            <b-icon
                              icon="receipt-cutoff"
                              aria-hidden="true"
                            ></b-icon>
                            Diligencie aquí formulario en línea</b-button
                          >
                        </center>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import {
  datosAudienciap,
  datosDenunciaagua,
  datosDenunciaaire,
  datosDenunciabosque,
  datosDenunciafauna,
  datosDenunciaflora,
  datosDenunciapaisaje,
  datosDenunciasuelo,
  datosDenunciaruido,
  datosDerechopeticionconsulta,
  datosDerechopeticioncertificaciones,
  datosDerechopeticioninteresgeneral,
  datosDerechopeticioncopias,
  datosDerechopeticioninteresparticular,
  datosentesdecontrol,
  datosinformescongresistas,
  datospeticionesentreautoridades,
  datoselogios,
  datoscitacionautojudi,
  datosdocuseguidereambioto,
  datosdocucontratacion,
  datosentregadocu,
  datosinfofaltantedereambi,
  datosinfoadiconaldereambi,
  datosporcicola,
  datoscostosopeseguimiento,
  datosrecursoapelacion,
  datosrecursoqueja,
  datosrecursoreposicion,
  datosrecursoreposicionapelacion,
  datosqueja,
  datosreclamocobrotua,
  datosreclamocobrotr,
  datosreclamopresentacionindebidacvc,
  datossugerencias,
  datosinvitacion,
  datosDenunciacorrupcion,
} from "../properties";
export default {
  data() {
    return {
      show: true,
      selected: null,
      rutas: {
        rutaformatopersonajuridica:
          "../../documentos/FT.0510.40 V02 20240331 Actualizacion de datos persona juridica.doc",
        rutaformatopersonaconrut:
          "../../documentos/FT.0510.41 V02 20240331 Actualizacion de datos persona natural con RUT.doc",
        rutaformatopersonasinrut:
          "../../documentos/FT.0510.42 V02 20240331 Actualizacion de datos persona natural sin RUT.doc",
      },
      options: [
        { value: null, text: "Formatos" },
        { value: "1", text: "Persona juridica" },
        { value: "2", text: "Persona natural sin RUT" },
        { value: "3", text: "Persona natural con RUT" },
      ],
    };
  },

  methods: {
    abrirNuevoTab(param) {
      // Abrir nuevo tab
      var url = param;
      var win = window.open(url, "_blank");
      // Cambiar el foco al nuevo tab (punto opcional)
      win.focus();
    },
    abrirDoc() {
      console.log(this.selected);
      let win = "";
      if (this.selected === "1") {
        win = window.open(this.rutas.rutaformatopersonajuridica, "_blank");
      } else if (this.selected === "2") {
        win = window.open(this.rutas.rutaformatopersonasinrut, "_blank");
      } else {
        win = window.open(this.rutas.rutaformatopersonaconrut, "_blank");
      }
      console.log(win);
    },
    urldatosAudienciap() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosAudienciap,
      });
    },
    urldatosDenunciaagua() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciaagua,
      });
    },
    urldatosDenunciaaire() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciaaire,
      });
    },
    urldatosDenunciabosque() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciabosque,
      });
    },
    urldatosDenunciafauna() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciafauna,
      });
    },
    urldatosDenunciaflora() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciaflora,
      });
    },
    urldatosDenunciapaisaje() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciapaisaje,
      });
    },
    urldatosDenunciasuelo() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciasuelo,
      });
    },
    urldatosDenunciaruido() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "pqrds",
        params: datosDenunciaruido,
      });
    },
    urldatosDerechopeticionconsulta() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitudDP",
        params: datosDerechopeticionconsulta,
      });
    },
    urldatosDerechopeticioncertificaciones() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitudDP",
        params: datosDerechopeticioncertificaciones,
      });
    },
    urldatosDerechopeticioninteresgeneral() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitudDP",
        params: datosDerechopeticioninteresgeneral,
      });
    },
    urldatosDerechopeticioncopias() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitudDP",
        params: datosDerechopeticioncopias,
      });
    },
    urldatosDerechopeticioninteresparticular() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitudDP",
        params: datosDerechopeticioninteresparticular,
      });
    },
    urldatosentesdecontrol() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosentesdecontrol,
      });
    },
    urldatosinformescongresistas() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosinformescongresistas,
      });
    },
    urldatospeticionesentreautoridades() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datospeticionesentreautoridades,
      });
    },
    urldatoselogios() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datoselogios,
      });
    },
    urldatoscitacionautojudi() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datoscitacionautojudi,
      });
    },
    urldatosdocuseguidereambioto() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosdocuseguidereambioto,
      });
    },
    urldatosdocucontratacion() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosdocucontratacion,
      });
    },
    urldatosentregadocu() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosentregadocu,
      });
    },
    urldatosinfofaltantedereambi() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosinfofaltantedereambi,
      });
    },
    urldatosinfoadiconaldereambi() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosinfoadiconaldereambi,
      });
    },
    urldatosporcicola() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosporcicola,
      });
    },
    urldatoscostosopeseguimiento() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datoscostosopeseguimiento,
      });
    },
    urldatosrecursoapelacion() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosrecursoapelacion,
      });
    },
    urldatosrecursoqueja() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosrecursoqueja,
      });
    },
    urldatosrecursoreposicion() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosrecursoreposicion,
      });
    },
    urldatosrecursoreposicionapelacion() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosrecursoreposicionapelacion,
      });
    },
    urldatosqueja() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosqueja,
      });
    },
    urldatosreclamocobrotua() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosreclamocobrotua,
      });
    },
    urldatosreclamocobrotr() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosreclamocobrotr,
      });
    },
    urldatosreclamopresentacionindebidacvc() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosreclamopresentacionindebidacvc,
      });
    },
    urldatossugerencias() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datossugerencias,
      });
    },
    urldatosinvitacion() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "solicitud",
        params: datosinvitacion,
      });
    },
    urldenunciacorrupcion() {
      //console.log(datosCesiondr);
      this.$router.push({
        name: "corrupcion",
        params: datosDenunciacorrupcion,
      });
    },
  },
  components: {},
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #000000;
}
</style>
